// jscs:disable
'use strict';
// generated by mdm:generate-frontend-constants on 2025-01-28T15:06:20.628006917
angular.module('metadatamanagementApp')
  .constant('ENV', 'test')
  .constant('VERSION', '2024.2.2')
  .constant('ElasticSearchProperties', {
    apiVersion: '7.x',
    logLevel: 'error',
    pageSize: 10
  });
