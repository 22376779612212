// Generated by CoffeeScript 1.8.0
(function() {
  "use strict";
  angular.module('ngShortcut', []).directive('shortcut', [
    '$document', function($document) {
      return {
        link: function(scope, element, attrs, controller) {
          var combo, combos, _i, _len, _results;
          combos = attrs.shortcut.split(',');
          _results = [];
          for (_i = 0, _len = combos.length; _i < _len; _i++) {
            combo = combos[_i];
            _results.push((function(combo) {
              var handler, keycode, modifiers, parts;
              parts = combo.split('-');
              keycode = parseInt(parts[parts.length - 1], 10);
              modifiers = parts.slice(0, parts.length - 1);
              handler = function(e) {
                var eventName, meta, _j, _len1, _ref;
                if (e.keyCode !== keycode || ('closest' in element && element.closest('.ng-leave').length)) {
                  return;
                }
                e.stopImmediatePropagation();
                _ref = ['shift', 'ctrl', 'alt', 'meta'];
                for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
                  meta = _ref[_j];
                  if (!(modifiers.indexOf(meta) === -1) !== e["" + meta + "Key"]) {
                    return;
                  }
                }
                if (scope.$eval(attrs.shortcutPreventDefault)) {
                  e.preventDefault();
                }
                eventName = attrs.shortcutEvent || 'click';
                if ('shortcutTriggerHandler' in attrs) {
                  return element.triggerHandler(eventName);
                } else {
                  return element.trigger(eventName);
                }
              };
              $document.on('keydown', handler);
              return element.on('$destroy', function() {
                return $document.off('keydown', handler);
              });
            })(combo));
          }
          return _results;
        }
      };
    }
  ]);

}).call(this);
